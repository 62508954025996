import React from "react"

const Image = props => {
  return (
    <div className="image-container">
      <img src={props.src} alt={props.alt} className="image" />
      <div className="overlay">{props.children}</div>
    </div>
  )
}

export default Image
