import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"

export default () => (
  <Layout>
    <div>
      <h1 style={{paddingLeft: "2rem"}}>Portfolio</h1>
      <Image src={require("../images/portfolio/imagine-yourself.jpg")} alt="Imagine Yourself">Imagine Yourself Podcast Branding</Image>
      <Link to="/websites"><Image src={require("../images/portfolio/websites800.jpg")} alt="Websites">Websites</Image></Link>
      <Image src={require("../images/portfolio/hearttree.jpg")} alt="Painting">Painting</Image>
      <Image src={require("../images/portfolio/AI-character.jpg")} alt="Character Design">Character Design</Image>
      <Image src={require("../images/portfolio/profile-1.jpg")} alt="Profile Drawing">Profile Drawing</Image>
      <Image src={require("../images/portfolio/profile-2.jpg")} alt="Profile Drawing">Profile Drawing</Image>
      <Image src={require("../images/portfolio/profile-3.jpg")} alt="Profile Drawing">Profile Drawing</Image>
      <Image src={require("../images/portfolio/profile-4.jpg")} alt="Profile Drawing">Profile Drawing</Image>
    </div>
  </Layout>
)

//            <Image src={require("../images/portfolio/portrait.jpg")} alt="Portrait">Portrait</Image>